import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill"
import Layout from "../Layout";
import { ButtonArrowRight } from "../components/UI/Button";
import Clients from "../components/Clients";
import Testimonials from "../components/Testimonials";
import loveIcon from '../images/Love.png';
import heroBG from '../images/hero_video_poster.jpg';

const Home = ({ location, data }) => {
  const content = data.contentfulHomePage;
  const blogroll = data.allContentfulBlog.nodes;
  const products = data.allContentfulProducts.nodes;
  const cases = data.allContentfulPortfolioDetail.nodes;
  const covidTravel = blogroll.filter(p => p.slug === "develop-and-launch-covid-travel-pwa-over-weekend")[0]
  const cny2022 = products.filter(p => p.slug === "happiness-prosperity-and-disco-in-the-year-of-the-tiger")[0]
  const crypTracker = products.filter(p => p.slug === "cryptocurrency-price-tracker-trading-volatile-coins")[0]
  const itsNotYouItsUS = products.filter(p => p.slug === "its-not-you-its-us")[0]
  const uxAudit = products.filter(p => p.slug === "rapid-user-experience-audit")[0]
  const insead = cases.filter(p => p.entrySlug === "insead")[0]
  const pepsi = cases.filter(p => p.entrySlug === "pepsi-refresh-everything")[0]

  return (
    <Layout location={location}>
      <div
        style={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage: `url(${heroBG})`,
          backgroundColor: "#000"
        }}
        className="about__header uk-height-min-large uk-cover-container uk-overflow-hidden uk-toggle">
        <div style={{ backgroundColor: "rgba(29, 38, 54, 0.6)", zIndex: "1" }} className="uk-position-cover" />
        <div className="vimeo__wrapper">
          <iframe
            src={`https://player.vimeo.com/video/728795942?h=65558c366f&background=1&muted=1&autoplay=1&loop=1&color=000000`}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            webkitallowfullscreen={`true`}
            mozallowfullscreen={`true`}
            allowFullScreen
            data-uk-video="autoplay: inview;"
            />
        </div>
        <div style={{ zIndex: "2" }} className="uk-padding-small uk-height-1-1 uk-position-relative uk-margin-large-top uk-light">
          <div className={`uk-flex uk-flex-middle uk-height-1-1`}>
            <div
              className={`uk-container uk-flex-auto uk-text-left`}
              data-uk-parallax="y: 0,312; opacity:0;media: @s">
              <div>
                <h1 style={{ lineHeight: "75%", fontDisplay: "block" }}
                  className="h1__xlarge home__hero__copy uk-margin-remove-vertical uk-text-uppercase">
                  Your Creative<br />Si<span style={{ letterSpacing: "-0.65rem" }}>l</span>ver Bulle<span style={{ letterSpacing: "-0.7rem" }}>t</span><span style={{ fontFamily: "Georgia, Avenir Black, Maven Pro" }}>.</span>
                </h1>
                <h2
                  style={{ lineHeight: "75%", fontDisplay: "block" }}
                  className="uk-margin-top home__hero__copy">
                  Right Here<span style={{ fontFamily: "Georgia, Avenir Black, Maven Pro" }}>.</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section style={{ borderBottom: "2px solid #fff" }} className="uk-background-astro-lightest">
        <div className="case_study_grid">

          <div className="cta_one uk-background-dusk">
            <div className="grid__item copy_one">
              <div className="content">
                <div className="content-inside uk-width-2-3@m">
                  <h3 className="h3__large uk-margin-remove-top uk-margin-medium-bottom uk-padding-remove uk-text-dusk-lightest">
                    Solving the creative talent crisis for the advertising industry.
                  </h3>
                  <ButtonArrowRight
                    url={`/portfolio/product-design/martech/breaks-spikes-awards/`}
                    label="View Case"
                    css={`uk-button-large cta-button uk-text-dusk-lightest`}
                    color={`#E0DBF3`} />
                </div>
              </div>
            </div>
            <div className="grid__item image_one">
              <div className="image-content">
                <div
                  style={{ width: "110%" }}
                  className="uk-position-absolute uk-position-z-index"
                  data-uk-parallax="y: -200">
                  <Img
                    fluid={data.breaks.childImageSharp.fluid}
                    alt="Breaks @Spikes mobile app."
                    objectFit="contain"
                    objectPosition="50% 50%" />
                </div>
                <div className="uk-flex uk-flex-middle uk-flex-center uk-height-1-1 uk-width-1-1">
                  <div
                    style={{ height: "80%" }}
                    className="uk-width-4-5@m uk-background-dusk-light"
                    data-uk-parallax="opacity: 0,1" />
                </div>
              </div>
            </div>
          </div>

          <div className="row_one">
            <div className={`row_one__content_one uk-position-relative uk-height-medium@s`}>
              <Img
                fluid={covidTravel.mainImage.localFile.childImageSharp.fluid}
                alt="Covid Travel App"
                className={`uk-height-1-1`}
                objectFit="cover"
                objectPosition="50% 50%" />
              <div className="uk-overlay-branded uk-position-cover">
                <div className="uk-position-top-left uk-padding-large uk-width-3-5@m">
                  <h2 className="h1__large uk-margin-remove">There wasn't an app for that.</h2>
                  <h3 className="uk-margin-top">So we made it.</h3>
                  <ButtonArrowRight
                    url={`/blog/develop-and-launch-covid-travel-pwa-over-weekend`}
                    label="Learn More"
                    css={`uk-button-large cta-button uk-text-astro-lightest`}
                    color={`rgba(244, 244, 243, 1)`} />
                </div>
              </div>
            </div>
            <div className={`row_one__content_two uk-position-relative uk-height-medium@s`}>
              <Img
                fluid={cny2022.heroImage.localFile.childImageSharp.fluid}
                alt="幸福,繁荣和迪斯科"
                className={`uk-height-1-1`}
                srcStyle={{ height: "150%" }}
                objectFit="cover"
                objectPosition="50% 50%" />
              <div className="uk-overlay-branded uk-position-cover">
                <div className="uk-position-bottom-left uk-position-large">
                  <h2 className="uk-text-astro-lightest">Happiness.<br />Prosperity.<br />And the Funk.</h2>
                  <h3 className="uk-text-astro-lightest">In the Year of<br />The Tiger.</h3>
                  <ButtonArrowRight
                    url={`/products/happiness-prosperity-and-disco-in-the-year-of-the-tiger/`}
                    label="Check it out"
                    css={`uk-button-large cta-button uk-text-astro-lightest`}
                    color={`rgba(244, 244, 243, 1)`} />
                </div>
              </div>
            </div>
          </div>
          <div className="cta_two uk-background-mustard">
            <div className="grid__item image_two">
              <div className="image-content">
                <div
                  style={{ width: "110%" }}
                  className="uk-position-absolute uk-position-z-index"
                  data-uk-parallax="y: -200">
                  <Img
                    fluid={data.deemoney.childImageSharp.fluid}
                    alt="kapitall"
                    objectFit="contain"
                    objectPosition="50% 50%" />
                </div>
                <div className="uk-flex uk-flex-middle uk-flex-center uk-height-1-1 uk-width-1-1">
                  <div
                    style={{ height: "80%" }}
                    className="uk-width-4-5@m uk-background-mustard-light"
                    data-uk-parallax="opacity: 0,1" />
                </div>
              </div>
            </div>
            <div className="grid__item copy_two">
              <div className="content">
                <div className="content-inside uk-width-2-3@m">
                  <h3 className="h3__large uk-margin-remove-top uk-margin-medium-bottom uk-padding-remove uk-text-mustard-lightest">Designing a smarter way to transfer funds in Thailand.</h3>
                  <ButtonArrowRight
                    url={`/portfolio/ux-ui-design/fintech/deemoney-progressive-web-app/`}
                    label="View Case"
                    css={`uk-button-large cta-button uk-text-mustard-lightest`}
                    color={`rgba(251, 237, 218, 1)`} />
                </div>
              </div>
            </div>
          </div>

          <div className="row_two">
            <div className={`row_two__content_one uk-position-relative uk-height-medium@s`}>
              <Img
                fluid={uxAudit.heroImage.localFile.childImageSharp.fluid}
                alt="Get a UX audit"
                className={`uk-height-1-1`}
                objectFit="cover"
                objectPosition="50% 50%" />
              <div className="uk-overlay-branded uk-position-cover">
                <div className="uk-position-center uk-padding uk-text-center uk-width-4-6@m uk-text-right">
                  <h3 className="">Solve UX problems before they become business problems.</h3>
                  <ButtonArrowRight
                    url={`/products/rapid-user-experience-audit/`}
                    label="Start Now"
                    css={`uk-button-large cta-button uk-text-astro-lightest`}
                    color={`rgba(244, 244, 243, 1)`} />
                </div>
              </div>
            </div>
            <div className={`row_two__content_two uk-position-relative uk-height-medium@s`}>
              <Img
                fluid={pepsi.entryTileImage.localFile.childImageSharp.fluid}
                alt="Pepsi Refresh Everything"
                className={`uk-height-1-1`}
                objectFit="cover"
                objectPosition="50% 50%" />
              <div className="uk-overlay-branded uk-position-cover">
                <div className="uk-position-center uk-padding uk-text-center uk-width-4-6@m">
                  <h3 className="">What if there was a magic button to Refresh Everything<span style={{ fontFamily: "Arial Black" }}>?</span></h3>
                  <ButtonArrowRight
                    url={`/portfolio/digital-marketing/fmcg/pepsi-refresh-everything/`}
                    label="View Case"
                    css={`uk-button-large cta-button uk-text-astro-lightest`}
                    color={`rgba(244, 244, 243, 1)`} />
                </div>
              </div>
            </div>
            <div className={`row_two__content_three uk-position-relative uk-height-medium@s`}>
              <Img
                fluid={itsNotYouItsUS.heroImage.localFile.childImageSharp.fluid}
                alt="ItNotYouItsUS: we're in this together"
                className={`uk-height-1-1`}
                objectFit="cover"
                objectPosition="50% 50%" />
              <div className="uk-overlay-branded uk-position-cover">
                <div className="uk-position-center uk-padding uk-text-center uk-width-1-2@m">
                  <h3 className="">We're all in this together.</h3>
                  <ButtonArrowRight
                    url={`/products/its-not-you-its-us/`}
                    label="Learn More"
                    css={`uk-button-large cta-button uk-text-astro-lightest`}
                    color={`rgba(244, 244, 243, 1)`} />
                </div>
              </div>
            </div>
          </div>

          <div className="cta_three uk-background-jungle">
            <div className="grid__item copy_three">
              <div className="content">
                <div className="content-inside uk-width-2-3@m">
                  <h3 className="h3__large uk-margin-remove-top uk-margin-medium-bottom uk-padding-remove uk-text-jungle-lightest">Winning awards with the world's first drag and drop investment platform.</h3>
                  <ButtonArrowRight
                    url={`/portfolio/product-design/fintech/kapitall/`}
                    label="View Case"
                    css={`uk-button-large cta-button uk-text-jungle-lightest`}
                    color={`rgba(230, 238, 222, 1)`} />
                </div>
              </div>
            </div>
            <div className="grid__item image_three">
              <div className="image-content">
                <div
                  style={{ marginLeft: "24px", width: "120%" }}
                  className="uk-position-absolute uk-position-z-index"
                  data-uk-parallax="y: -200">
                  <Img
                    fluid={data.kapitall.childImageSharp.fluid}
                    alt="Kapitall. Investing for the rest of us."
                    objectFit="contain"
                    objectPosition="50% 50%" />
                </div>
                <div className="uk-flex uk-flex-middle uk-flex-center uk-height-1-1 uk-width-1-1">
                  <div
                    style={{ height: "80%" }}
                    className="uk-width-4-5@m uk-background-jungle-light"
                    data-uk-parallax="opacity: 0,1" />
                </div>
              </div>
            </div>
          </div>

          <div className="row_three">
            <div className={`row_three__content_one uk-position-relative uk-height-medium@s`}>
              <Img
                fluid={insead.entryTileImage.localFile.childImageSharp.fluid}
                alt="INSEAD creative technology case study"
                className={`uk-height-1-1`}
                objectFit="cover"
                objectPosition="50% 50%" />
              <div className="uk-overlay-branded uk-position-cover">
                <div className="uk-position-bottom-left uk-position-medium uk-text-left">
                  <h3 className="">Schoolin' a business school in Brand Experience.</h3>
                  <ButtonArrowRight
                    url={`/portfolio/digital-marketing/education/insead/`}
                    label="View Case"
                    css={`uk-button-large cta-button uk-text-astro-lightest`}
                    color={`rgba(244, 244, 243, 1)`} />
                </div>
              </div>
            </div>
            <div className={`row_three__content_two uk-position-relative uk-height-medium@s`}>
              <Img
                fluid={crypTracker.heroImage.localFile.childImageSharp.fluid}
                alt="A little crypto app for monitoring day trades"
                className={`uk-height-1-1`}
                objectFit="cover"
                objectPosition="50% 50%" />
              <div className="uk-overlay-branded uk-position-cover">
                <div className="uk-position-top-right uk-padding-large uk-text-right">
                  <h2 className="h2__large uk-margin-medium-bottom"><span className="uk-text-nowrap">Watch your bags.</span><br /> Not the market.</h2>
                  <ButtonArrowRight
                    url={`/products/cryptocurrency-price-tracker-trading-volatile-coins/`}
                    label="Learn More"
                    css={`uk-button-large cta-button uk-text-astro-lightest`}
                    color={`rgba(244, 244, 243, 1)`} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section uk-section-xlarge uk-background-astro-darker">
        <div className="uk-flex uk-flex-center">
          <div className="uk-width-5-6 uk-width-3-5@m">
            <p className="uk-h6 uk-text-astro-light uk-margin-small-bottom uk-text-uppercase">
              <strong>&mdash;</strong> Our Process
            </p>
            <h2 className="uk-margin-remove-top uk-margin-bottom uk-display-block uk-text-astro">
              <div className="uk-margin-small-bottom">Start with a great idea.</div>
              <div className="uk-margin-small-bottom">Figure out how to make it real.</div>
              <div>Create an experience <span className="uk-text-nowrap">people
                <img className="uk-margin-small-left" src={loveIcon} height="40" width="40" alt="Love" /></span></div>
            </h2>
          </div>
        </div>
      </section>
      <Clients />
      <Testimonials data={content.testimonials} />
    </Layout>
  )
}
export default Home;

export const homeQuery = graphql`
    fragment homeFolioImage on File {
        childImageSharp {
            fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    query homeQuery {
          breaks: file(relativePath: { eq: "breaks_mobile2.png" }) {
            ...homeFolioImage
          }
          deemoney: file(relativePath: { eq: "deemoney.png" }) {
            ...homeFolioImage
          }
          kapitall: file(relativePath: { eq: "kapitall.png" }) {
            ...homeFolioImage
          }
          allContentfulBlog(sort: { fields: date, order: DESC }) {
              nodes {
                slug
                title
                subtitle {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
                mainImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2400, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
              }
            }
          allContentfulProducts {
              nodes {
                slug
                date
                title
                subtitle {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
                mainImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2400, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
                heroImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2400, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
              }
            }
          allContentfulPortfolioDetail {
              nodes {
                entrySlug
                entryHeadline
                entrySubHeadline {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
                entryTileImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2400, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
                entryHeroImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2400, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
              }
            }
          contentfulHomePage {
              pageTitle
              introduction {
                introduction
              }
              profile {
                headline
                content {
                  content
                }
                heroImages {
                  fluid(maxWidth: 1600, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
              testimonials {
                quote {
                  quote
                }
                source
                role
              }
            }
            allContentfulProducts {
              nodes {
                slug
                date
                title
                subtitle {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
                mainImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2400, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
                heroImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2400, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
              }
            }
          }`