import React from 'react';
import * as style from '../Content/MessageCarousel.module.css';

const Testimonials = ({ data }) => {
    return (
        <section
            className="uk-section uk-section-large uk-background-astro-lighter">
            <div className="uk-flex uk-flex-center">
                <div className="uk-width-5-6 uk-width-1-2@m">
                    <div className={`${style.messageItem} uk-position-relative uk-margin-auto-left uk-margin-auto-right`} data-uk-slider="autoplay: true;">
                        <p className="uk-h6 uk-text-muted uk-margin-xlarge uk-text-uppercase">
                            <strong>&mdash;</strong> Nice things people have said
                        </p>
                        <ul className="uk-slider-items uk-child-width-1-1 uk-margin-top">
                            {data.map((d, i) =>
                                <li key={`quote-${i}`}>
                                    <p className={`${style.quote} uk-text-medium`}>
                                        <span className="uk-dropcap uk-float-left"
                                            data-uk-icon="icon: quote-right; ratio: 3;" />
                                        {d.quote.quote}"
                                    </p>
                                    <div className="uk-margin-large">
                                        <p className="uk-text-center uk-text-bold uk-margin-remove">{d.source}</p>
                                        <p className="uk-text-muted uk-text-center uk-margin-remove">{d.role}</p>
                                    </div>
                                </li>
                            )}
                        </ul>
                        <div className="uk-margin-remove-bottom">
                            <ul className="uk-slider-nav uk-dotnav uk-flex-center"></ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Testimonials;
