import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill"

const ClientLogos = props => {

    const logos = useStaticQuery(graphql`
        query clientQuery {
          allFile(filter: {extension: {regex: "/(png)/"}, relativeDirectory: {eq: "client_logos"}}) {
            edges {
              node {
                name
                childImageSharp {
                  fluid(maxWidth: 256, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `);

    return (
        logos.allFile.edges.map((logo, i) =>
            <div key={i}>
                <Img
                    fluid={logo.node.childImageSharp.fluid}
                    alt={logo.node.name}
                />
            </div>
        )
    )
}


const Clients = props =>
    <section className="uk-section uk-section-large uk-background-astro-lightest uk-width-1-1 uk-flex uk-flex-column uk-flex-center uk-flex-middle">
        <div className="uk-width-3-5@s uk-padding-small">
            <div>
                <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                    <strong>&mdash;</strong> Some of our clients
                </p>
                <h3 className="uk-margin-remove-top">
                    <span className="uk-display-block">
                        Pride in the<br />company we keep.
                    </span>
                </h3>
                <h5>*no agency required</h5>
            </div>
            <div>
                <div className="client-grid uk-grid uk-grid-large uk-child-width-1-3 uk-child-width-1-5@m">
                    {<ClientLogos />}
                </div>
            </div>
        </div>
    </section>

export default Clients;
